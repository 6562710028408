import { Routes } from "@angular/router";

import { Permissions } from "src/assets/config/config";
import { overviewRoutes } from "./features/overview";
import { myTicketsRoutes } from "./features/my-tickets";
import { authGuard, landingGuard, roleGuard } from "./core";
import { adminRoutes, authRoutes, servicesRoutes } from "./features";

export const routes: Routes = [
  { path: "", redirectTo: "overview", pathMatch: "full" },
  {
    path: "auth",
    canActivate: [authGuard],
    children: authRoutes,
  },
  {
    path: "overview",
    canActivate: [landingGuard],
    children: overviewRoutes,
  },
  {
    path: "transaction/:transactionType",
    loadComponent: () => import("src/app/shared/components/ticket/ticket.component").then((c) => c.TicketComponent),
  },
  {
    path: "ticket/:reservationNumber",
    loadComponent: () => import("src/app/shared/components/ticket/ticket.component").then((c) => c.TicketComponent),
  },
  {
    path: "",
    canActivateChild: [authGuard],
    loadComponent: () => import("./core/components/layout/layout.component").then((c) => c.LayoutComponent),
    children: [
      { path: "", redirectTo: "services", pathMatch: "full" },
      {
        path: "",
        children: [
          {
            path: "services",
            canActivate: [roleGuard],
            data: { permissions: Permissions.Services },
            children: servicesRoutes,
          },
          {
            path: "my-tickets",
            canActivate: [roleGuard],
            data: { permissions: Permissions.MyTickets },
            children: myTicketsRoutes,
          },
          {
            path: "admin",
            canActivate: [roleGuard],
            data: { permissions: Permissions.Admin },
            children: adminRoutes,
          },
        ],
      },
    ],
  },
];
